import React from 'react'
import {graphql} from 'gatsby'
import Layout from 'gatsby-theme-psg/src/components/Layout'
import Header from '../components/header/Header'
import Lead from '../components/header/Lead'

const TimetablePage = ({data}) => {
    const { markdownRemark: post } = data
    return (
        <Layout title={post.frontmatter.title} description={post.frontmatter.description}>
            <Header>
                <Lead title={post.frontmatter.title} content={post.html} />
            </Header>
        </Layout>
    )
}

export default TimetablePage

export const query = graphql`
    query TimetablePage($id: String!) {
        markdownRemark(id: { eq: $id }) {
            html
            frontmatter {
                title
                description
            }
        }
  }
`
